.benefits-wrapper{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 300px;

  .zarmada-benefit {
    background-color: #02224f;
    color: #fff;
    display: flex;
    flex-direction: column;
    width: 25.260vw;
    border-radius: 30px;
    padding: 28px 31px 39px;

    &.highlights {
      margin-bottom: 24px;
    }

    .benefit-content {
      opacity: 1;
      .image-wrap {
        height: 58px;
        margin: 0 0 15px 0;
        .benefit-img {
          width: 46px;
          height: 58px;
        }
        .benefit-img-wide{
          width: 98px;
          height: 53px;
        }
      }
      h2 {
        font-family: 'Qanelas Bold';
        font-size: 1.250vw;
        font-weight: bold;
        line-height: 1.75;
        letter-spacing: 4.8px;
        margin: 0 0 10px 0;
      }
      .separator {
        width: 100%;
        border-bottom: 1px solid #5e779c;
        margin: 0 0 23px 0;
      }
      span {
        font-size: 0.833vw;
        font-weight: 300;
        line-height: 1.63;
        letter-spacing: 0.8px;
      }
    }
  }
}

/*** gsap ***/
@media (min-width: 1680px) {
  .main-section.about-section {
    h1 {
      padding-top: 100px;
    }
  }
}

/*** responsiveness ***/
/*  iPad - Landscape  */
@media (max-width: 1366px) {
  .main-section.about-section{
    padding-top: 12vw;
  }
  .benefits-wrapper{
    flex-direction: column;
    margin-bottom: 0;
    padding: 0;

    .zarmada-benefit{
      width: 100%;
      margin-bottom: 6.667vw;

      &:last-of-type{
        margin-bottom: 0;
      }

      .benefit-content{
        h2{
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.75;
          letter-spacing: 4.8px;
          text-align: left;
          color: #fff;
        }
        span {
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.44;
          letter-spacing: 0.9px;
          text-align: left;
          color: #fff;
          display: inherit;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .main-section.about-section{
    margin-top: 0;
    padding-top: 20vw;
  }

  .benefits-wrapper{
    flex-direction: column;
    margin-bottom: 0;
    padding: 0;

    .zarmada-benefit{
      width: 100%;
      margin-bottom: 6.667vw;

      &:last-of-type{
        margin-bottom: 0;
      }

      .benefit-content{
        h2{
          font-size: 3.200vw;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.75;
          letter-spacing: 4.8px;
          text-align: left;
          color: #fff;
        }
        span {
          font-size: 2.400vw;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.44;
          letter-spacing: 0.9px;
          text-align: left;
          color: #fff;
          display: inherit;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .benefits-wrapper .zarmada-benefit .benefit-content{

    h2 {
      font-size: $phone-card-heading;
    }

   span {
      font-size: $phone-card-description;
    }
  }
}