.highlights {
  z-index: 1;

    &.main-wrap {
      padding-bottom: 50px;
    }

    &.main-section--row.hero {
      margin-top: 150px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1366px) {
        flex-direction: column-reverse;
        width: -webkit-fill-available;
      }
    }

    .hero-heading {
      font-size: 72px;
      font-weight: bold;
      color: #02224f;
      font-family: 'Qanelas Bold';
      text-align: left;
      letter-spacing: 1.8px;
      margin-bottom: 55px;
      position: relative;
      background-image: url("/assets/images/highlights/Highlights_HighlightElement-Large.svg");

      @media (max-width: 1366px) {
        text-align: left;
        font-size: 68px;
        background-image: url("/assets/images/highlights/Highlights_HighlightElement-MobileHeroTitle.svg");
      }

      @media (max-width: 450px) {
        font-size: 38px;
      }
    }


    .info-section.hero {
      display: flex;
      flex-direction: column;
      width: 655px;
  
      span {
        font-family: 'Qanelas Regular';
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 1.2px;
        text-align: left;
        color: #5e779c;
      }

      .row {
        display: grid;
        grid-template-columns: .6fr 1fr;
        grid-gap: 30px;
        align-items: center;
      }
      @media (max-width: 1366px) {
        width: 100%;
        display: none;
      }

      @media (max-width: 450px) {
        span {
          font-size: $phone-description;
        }
      }
    }

    .info-section.highlights.mobile.hero {
      display:  none;
      @media (max-width: 1366px) {
        display: flex;
        flex-direction: column;
      }
    }
  
    .highlights-image {
      height: 500px;
      background-color: transparent;
      box-shadow: 0px 0px 30px 2px rgba(168,168,168,0.44);
      z-index: 1;
      @media (max-width: 1680px) {
        width: fit-content;
        height: 440px;
        margin-left: 70px;
      }

      @media (max-width: 1366px) {
        display: none;
        width: 100%;
        height: auto;
        margin: 0;
        margin-bottom: 40px;
        &.mobile {
          display: block;
        }
      }
    }

    .benefits-wrapper {
      @media (max-width: 450px) {
        padding-bottom: 100px;
      }
    }
  }


.main-section--row .info-section .info-wrapper .main-section--heading.highlights {
  font-family: 'Qanelas Bold';
  margin: 0 0 23px 0;
  color: #02224f;
  font-size: 36px;
  line-height: 1.17;
  letter-spacing: 3.6px;
  text-align: left;
  position: relative;
  width: auto;
}

.main-section--description-item.highlights {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: 1.2px;
  text-align: left;
  color: #5e779c;
  width: 25.104vw;
  margin-bottom: 30px;


  @media (max-width: 1366px) {
    max-width: 677px;
    width: 100%;
    text-align: center;
    &:last-of-type {
      margin-bottom: 100px;
    }
  }
  @media (max-width: 450px) {
   
    font-size: $phone-description;
  }
}