.main-section.contact-section {
  padding-top: 5.208vw;
  margin: 0 0 10.417vw;

  .main-section--description{
    width: 36.875vw;
  }

  .contact-section--content{
    display: flex;
    justify-content: space-around;
    width: 100%;

    #map{
      width: 612px;
      height: 473px;
      border-radius: 30px;

      //  Map styles override
      .gmnoprint, .gm-style-cc{
        display: none;
      }
    }
    .contact-us-form-wrapper{
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 485px;

      label {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 2.8px;
        text-align: left;
        color: #02224f;
      }

      .contact-input{
        background-color: #fff;
        border-radius: 10px;
        height: 50px;
        border: none;
        padding: 18px 22px;
        line-height: 24px;
        color: #222;
        margin-bottom: 10px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 100;
        &:focus {
          outline:none; 
          border:1px solid #0559d1;
          -webkit-box-shadow: 0px 0px 5px  #0559d1;
          box-shadow: 0px 0px 5px  #0559d1
        }

        &::placeholder{
          color: rgba(2, 34, 79, 0.5);
          font-size: 16px;
          font-weight: 100;
        }
      }

      .contact-text-area{
        height: 150px;
        color: #222;
        font-size: 16px;
        font-weight: 100;
        margin-bottom: 5px;
        resize: none;
        
      }

      .recaptcha-branding {
        font-size: 14px;
        color: #02224f;
        margin-bottom: 20px;

        a {
          color: #0559d1;
        }
      }

      .contact-button{
        font-family: 'Qanelas Bold';
        color: #fff;
        background-color: #0559d1;
        font-size: 16px;
        font-weight: 100;
        height: 50px;
        border-radius: 10px;
        border: none;
        width: 232px;
        line-height: 36px;
        letter-spacing: 3.2px;

        &:focus, &:active {
          outline:none; 
          border:1px solid #0559d1;
          -webkit-box-shadow: 0px 0px 5px  #0559d1;
          box-shadow: 0px 0px 5px  #0559d1
        }
      
        &:hover{
          background-color: #0552BD;
        }
      }
    }
  }
  .contact-error {
    display: none;
    color: red;
    font-size: 12px;
    margin-bottom: 12px;
    &.active {
      display: block;
    }
  }
  #contact-success {
    display: none;
    color: limegreen;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 12px;
    &.active {
      display: block;
    }
  }
  #contact-failure {
    display: none;
    color: red;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 12px;
    &.active {
      display: block;
    }
  }
}


/*** gsap ***/
.main-section.contact-section {
  margin: 0 0 150px;
}

/*** responsiveness ***/
/*  iPad - Landscape  */
@media (max-width: 1366px) {
  .main-section.contact-section {
    padding-top: 12vw;
    .main-section--description{
      width: 667px;
    }
    .contact-section--content{
      flex-direction: column-reverse;
      align-items: center;

      #map{
        width: 100%;

      }

      .contact-us-form-wrapper {
        max-width: unset;
        margin-bottom: 10vw;
      }   
    }
  }
}


@media (max-width: 768px) {
  .main-section.contact-section {
    padding-top: 20vw;
    margin: 0 0 20vw;

    .main-section--description{
      width: 100%;
    }

    .contact-section--content{
      flex-direction: column-reverse;
      align-items: center;
      #map{
        width: 112%;
      }
      .contact-us-form-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 485px;
        margin-bottom: 10vw;

        label {
          font-size: 2.400vw;
          font-family: 'Qanelas Bold';
          font-stretch: normal;
          font-style: normal;
          line-height: 2.33;
          letter-spacing: 3.6px;
          text-align: left;
          color: #02224f;
        }

        .contact-input{
          font-size: 2.400vw;
          margin-bottom: 1.333vw;
          padding: 2.400vw 2.933vw;
          &::placeholder{
            font-size: 2.400vw;
          }
        }

        .contact-text-area {
          font-size: 2.400vw;
          height: 150px;
          margin-bottom: 4vw;
        }

        .recaptcha-branding {
          font-size: 1.867vw;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .main-section.contact-section {
    .contact-section--content{
      .contact-us-form-wrapper{
        label {
          font-size: 3.400vw;
        }
        .contact-input{
          font-size: 3.400vw;
          height: 40px;
          &::placeholder{
            font-size: 3.400vw;
          }
        }

        .contact-text-area {
          font-size: 3.400vw;
          height: 115px;
        }

        .recaptcha-branding {
          font-size: 2.867vw;
        }

        .contact-button {
          height: 40px;
        }
      }
    }
  }
}