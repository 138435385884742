
.main-wrap.giving{
  .main-section{
    .main-section--description{
      width: 37.979vw
    }
  }


  .benefits-wrapper {
    margin-bottom: 200px;
    .zarmada-benefit {
      .benefit-content {
        .image-wrap {
          .charitable-img {
            width: 82px;
            margin-top: -18px;
            height: auto;
            margin-left: -20px;
          }
          .charitable-img-wide{
            width: 86px;
            height: auto;
            margin-top: -18px;
          }
        }
      }
    }
  }
}

.main-section.chosen-charities{
  .main-section--description{
    width: 37.979vw
  }
}

.counter-wrapper {
  color: #fff;
  height: 26.042vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
  margin-bottom: 200px;
  background-color: #02224f;
  overflow: hidden;
  position: relative;

  .counter-background {
    position: absolute;
    background-size: cover;
    border-radius: 20px;
    background-image: url("/assets/images/CharityBGImage.jpeg");
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &.counter-background--visible{
      opacity: 1;
    }
  }
  
  .counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
   
    img {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      background: white;
      border-radius: 20px;
    }

    .counter-title {
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: 1.2px;
      text-align: center;
      color: #fff;
    }
    .counter-number {
      font-family: 'Qanelas Bold';
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

.carousel-section.carousel-section-giving {
  padding-bottom: 0px;
  margin-bottom: 10.417vw;
}

.carousel.carousel-giving{ 
  margin-bottom: 450px;
  height: 28vw;

  .carousel-indicators { 
    bottom: -75px;
    li{
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .carousel-inner {
    height: 220%;  
    .carousel-item {
      height: auto;
      height: 28.229vw;
      img {
        max-height: 28.229vw;
        min-height: 28.229vw;
        object-fit: cover;
      }
    }

    .carousel-caption {
      bottom: -210px;

      .story-permalink{
        font-family: "Qanelas Bold";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 3.2px;
        text-align: center;
        color: #0559d1;
        text-transform: uppercase;

        &:hover{
          text-decoration: none;
        }
      }
    }

  }
}

.charities{
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  width: 51.667vw;
  margin-bottom: 200px;
  

  .category-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 15px;

    .separator-wrap{
      flex: 1 1 auto;
  
      .separator {
        border-top: 1px solid #0559d1;
        display: block;
      }
    }
    .category-title {
      padding: 0 14.4px;
      font-family: "Qanelas Bold";
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.33;
      letter-spacing: 3.6px;
      text-align: center;
      color: #02224f;
    }
  }

  .charity{
    display: flex;
    margin-bottom: 30px;

    .img-wrap {
      max-width:231px;
      min-width:231px;
      max-height: 200px;
      padding: 0 15px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 62px;
      padding: 25px 15px;

      img {
        max-width: 90%;
      }
    }

    .charity-info {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 0 15px;

      h2 {
        font-family: "Qanelas Bold";
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: 4.8px;
        text-align: left;
        color: #5e779c;
        text-transform: uppercase;
      }

      p {
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: 0.8px;
        text-align: left;
        color: #02224f;

        a {
          color: #0559d1;
          text-decoration: none;
          &:hover{
            color: #0559d1;
          }
        }
      }
    }
  }
}

/*** gsap ***/
@media (min-width: 1680px) {
  .main-wrap.giving {
    .main-section{ 
      .main-section--heading {
        &#charitable-heading{
          padding-top: 100px;
        }
      }
    }
  }
}

/*** responsiveness ***/
@media (max-width: 1366px) {
  .main-wrap.giving{
    .main-section{
      padding-top: 12vw;

      .main-section--description {
        width: 667px;
      }
      .counter-wrapper{
        margin-bottom: 0;
      }
    }
  }

  .main-section.chosen-charities .main-section--description {
    width: 667px;
  }

  .carousel.carousel-giving{ 

    .carousel-indicators{
      bottom: -20.5%;
    }
    .carousel-inner {
      .carousel-item {
        .carousel-caption {
          bottom: -250px;
        }
      }
    }
  }

  .charities {
      width: 76vw;
  }
}

@media (max-width: 1050px) {
  .main-wrap.giving{
    .counter-wrapper{
      flex-direction: column;
      height: fit-content;
      padding: 10vw 15px;
      margin-bottom: 0;

      .counter {
        margin-bottom: 50px;

        img {
          width: 13.333vw;
          height: 13.333vw;
        }

        &:last-of-type {
          margin-bottom: 0px;
        }

        .counter-number {
          font-size: 4.800vw;
        }

        .counter-title{
          font-size: 3.200vw;
        }
      }
    }
  }

}

@media (max-width: 768px) { 
  .main-wrap.giving{
    .main-section{
      margin-top: 20vw;
      .main-section--description{
        width: 100%;
      }
    }

    .benefits-wrapper {
      margin-bottom: 6.667vw;
    }
  }

  .main-section.chosen-charities{
    .main-section--description{
      width: 100%
    }
  }

  .carousel.carousel-giving{ 
    height: 51.067vw;
    margin-bottom: 57vw;

    .carousel-indicators{
      bottom: -20.5%;
    }

    .carousel-inner {
      height: 110vw;  
      .carousel-item {
        height: 51.067vw;
        .carousel-caption {
        }
        img{
          height: 51.067vw;
          max-height: unset;
        }
      }
    }
    .carousel-control-prev, .carousel-control-next {
      height: 51.067vw;
    }
  }

  .charities {
    width: 100%;
    margin-bottom: 100px;

    .category-header {
      .category-title{
        font-size: 2.400vw;
      }
    }

    .charity {
      flex-wrap: wrap;

      .charity-info{
        h2 {
          font-size: 3.200vw;
        }
        p {
          font-size: 2.133vw;
        }
      }

      .img-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .img-wrap{
        width: 100%;
        margin-bottom: 35px;
        margin-right: 0;

        & img {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 450px) { 
  .carousel-section.carousel-section-giving{
    padding-bottom: 45px;
    margin-bottom: 100px;
  }

  .carousel.carousel-giving .carousel-indicators {
    bottom: -22.5%;
  }

  .carousel.carousel-giving .carousel-inner .carousel-item .carousel-caption {
    top: 108%;
  }

  .charities{

    .category-header .category-title {
      font-size: $phone-charity-category;
    }

    .charity .charity-info {
      h2 {
        font-size: $phone-charity-heading;
      }
      p {
        font-size: $phone-card-description;
      }
    }
  }
}