.carousel-section{
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding-bottom: 300px
}

.carousel { 
  width: 100%;
  max-width: 992px;
  height: 542px;

  .carousel-indicators {
    bottom: -69px;

    li {
      width: 72px;
      height: 5px;
      opacity: 0.4;
      background-color: #032d69;
      border-top: none;
      margin-right: 10px;
      margin-left: 10px;
    }

    li.active {
      opacity: 1;
    }
  }

  .carousel-inner {
    height: 140%;  

    .carousel-item {
      padding: 0 10px;
      img {
        border-radius: 30px;
      }
    }

    .carousel-caption {
      color: #02224f;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: 0.8px;
      top: 120%;

      .member-title {
        color: #5e779c;
        font-family: 'Qanelas Bold';
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: 4.8px;
        text-transform: uppercase;
      }
    }

    .member-social-wrapper {
      position: absolute;
      bottom: 40px;
      left: 43%;
      display: flex;
      justify-content: center;
      color: #fff;
      opacity: 1;

      .social-item{
        height:50px;
        width:50px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          width: 100%;
          height: 100%;
        }

        &:hover{
          transition: transform .5s ease-in-out;
          transform: translateY(-4px) !important;
        }
      }
      .social-item:first-child{
        margin-right: 20px;
      }
      .social-item.social-item--linkedIn{
        background-image: url("/assets/images/2021-Zarmada_Brand_Development_Icon-TeamCarousel-LinkedIn.svg");
      }
      .social-item.social-item--mail{
        background-image: url("/assets/images/2021-Zarmada_Brand_Development_Icon-Footer-Email.svg");
      }
    }

  }

  .carousel-control-prev {
    .carousel-control-prev-icon {
      background-image: url("/assets/images/2021-Zarmada_Brand_Development_Chevron-TeamCarousel-Left.svg");
      width: 26px;
      height: 51px;
    }
  }

  .carousel-control-next {
    .carousel-control-next-icon {
      background-image: url("/assets/images/2021-Zarmada_Brand_Development_Chevron-TeamCarousel-Right.svg");
      width: 26px;
      height: 51px;
    }
  }

  .carousel-control-next, .carousel-control-prev {
    width: 10%;
  }
}

/*** responsiveness ***/
@media (max-width: 768px) {
  .carousel-section{
    padding: 0 3.333vw 300px;
  }
  
  .carousel {
    width: 100%;
    height: 46vw;

    .carousel-inner {
      height: 300%;
      .carousel-caption {
        width: 100%;
        top: 115%;
        left: 0;

        .member-title {
          font-size: 3.200vw;
        }

        .member-description {
          font-size: 2.400vw;
          padding: 0px 6vw;
        }
      }
      .member-social-wrapper {
        .social-item{
          height:7vw;
          width:7vw;
        }
      }
    }

    .carousel-control-prev {
      .carousel-control-prev-icon {
        width: 20px
      }
    }

    .carousel-control-next {
      .carousel-control-next-icon {
        width: 20px
      }
    }

    .carousel-control-next, .carousel-control-prev {
      height: 46vw;
      width: 15%;
    }
  }
}



@media (max-width: 450px) {
  .carousel {
    .carousel-inner {
      .member-social-wrapper {
        bottom: 15px;
      }
      .carousel-caption {
        top: 120%;

        .member-title {
          font-size: $phone-carousel-heading;
        }

        .member-description {
          font-size: $phone-carousel-description;
        }
      }
    }
  }
}

