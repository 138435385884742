.hero-background {
  height: 200px;
  width: 100vw;
  position: absolute;
  top: 0;
  background-color: #fff;
}
.hero-section {
  width: 100%;
  background-color: #fff;
  padding: 0px 140px 0;

  .hero-container {
    display: flex;
    height: 53.125vw;
    background: #02224f;
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
    position: relative;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
      transition: opacity 1s ease-in-out;
      
      &.video-visible {
        opacity: 1;
      }
    }

    .hero-content{
      position: absolute;
      flex-direction: column;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 0 10vw;
      z-index: 1;
      text-align: center;

      .logo-2021 {
        width: 400px;
        height: auto;
        margin-bottom: 100px;
      }

      h1 {
        font-family: 'Qanelas Bold';
        margin-bottom: 30px;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 4px;
      }

      span {
        font-size: 24px;
        font-weight: 300;
        line-height: 1.33;
        letter-spacing: 1.2px;
        max-width: 732px;
        text-align: center;
        margin-bottom: 125px;
      }

      .arrows-down{
        width: 47px;
        height: auto;
      }
    }
  }
}


/*** responsiveness ***/
@media (max-width: 1920px) {
  .hero-section {
    padding: 0px 8.333vw 0;
  }
}
@media (max-width: 1366px) {
  .hero-section {
    .hero-container{
      height: 1080px;
    }
  }
}

@media (max-width: 1029px) {
  .hero-section {
    padding-top: 100px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 100px 3.333vw 0;
    .hero-container {
      height: 144vw;
      .hero-content{
        .logo-2021 {
          width: 53.333vw;
        }

        h1 {
          width: 60.533vw;
          text-align: center;
          margin-bottom: 28px;
          font-size: 4.8vw;
        }

        span {
          width: 66.667vw;
          font-size: 3.2vw;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .hero-section {
    .hero-container {
      background-image: url("/assets/images/waves-bg.png");
      background-size: cover;
      .hero-content{
        .logo-2021, span {
          margin-bottom: 30px;
        }

        h1 {
          font-size: $phone-heading;
        }

        span {
          font-size: $phone-description;
        }
      }
    }
  }
}