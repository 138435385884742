.main-section.team-section {
  padding-top: 100px;
  
  .main-section--description{
    width: 40.357vw;
    margin: 0 0 59px 0;
  }
}

.carousel-section {
  margin-bottom: 5.208vw;
}

.carousel { 
  .carousel-indicators {
    bottom: -2.969vw;
  }
  .carousel-inner {
    height: 280%;

    .carousel-item {
      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .carousel-caption {
      top: 109%;
    }
  }
}

/*** responsiveness ***/
@media (max-width: 1680px) {
  .main-section.team-section {
    .main-section--description{
      width: 41.726vw;
    }
  }
}

/*  iPad - Landscape  */
@media (max-width: 1366px) {
  .main-section.team-section {
    padding-top: 0px;
    .main-section--heading{
      padding-top: 12vw;
    }
    .main-section--description{
      width: 667px;
    }
  }

  .carousel-section {
    padding: 0 calc(8.333vw - 10px) 300px;
    margin-bottom: 0;
  }
  
  .carousel { 
    .carousel-indicators {
      bottom: -60px;
    }
  }

  .carousel-inner {
    height: 280%;
  }
}


@media (max-width: 768px) {
  .main-section.team-section {
    padding-top: 0px;
    .main-section--heading{
      padding-top: 20vw;
    }
    .main-section--description{
      width: 100%;
    }
  }

  .carousel-section {
    padding-bottom: 250px;  
    padding-bottom: 50vw;  
  }
  
  .carousel { 
    height: 46vw;

    .carousel-indicators {
      bottom: -65px;
    }
    .carousel-inner {
      height: 105vw;
      .carousel-item {
        height: auto;
      }
    }
  }
}

@media (max-width: 450px) {
  .carousel-section {
    padding-bottom: 300px;  
  }

  .carousel {
    .carousel-indicators {
      bottom: -55px;
    }
  }
}
