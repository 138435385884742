
//  Mobile -- Font-styles
$phone-heading: 5vw;
$phone-description: 3.7vw;
$phone-card-heading: 3.5vw;
$phone-card-description: 3vw;
$phone-expertise-item-heading: 3.5vw;
$phone-expertise-item-description: 3vw;
$phone-expertise-item--letter-spacing: 2px;
$phone-carousel-heading: 3.5vw;
$phone-carousel-description: 3vw;
$phone-footer-text: 3vw;
$phone-charity-category: 3vw;
$phone-charity-heading: 3.5vw;
$phone-charity-description: 3vw;
$phone-success-story-post-content: 3.5vw;