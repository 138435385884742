.expertise-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 120%;


  .expertise-item{
    display: flex;
    margin-bottom: 40px;

    .expertise-image {
      width: 105px;
      height: 105px;
      margin-right: 40px; 
      border-radius: 20%;
      box-shadow: 0px 0px 10px 2px rgba(168,168,168,0.44);
    }

    .expertise-info{
      display: flex;
      flex-direction: column;

      .expertise-title{
        font-family: 'Qanelas Bold';
        font-size: 24px;
        line-height: 1.75;
        letter-spacing: 4.8px;
        color: #5e779c;
        margin: 0 0 5px 0;
      }

      .expertise-description{
        font-size: 16px;
        line-height: 1.63;
        letter-spacing: 0.8px;
        color: #02224f;
        max-width: 24.844vw;
      }
    }
  }
}


.main-section--row {
  width: 100%;
  display: flex;
  flex-direction: row;

  .info-section {
    width: 100%;

    .info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 8vw;

      .main-section--heading {
        font-family: 'Qanelas Bold';
        margin: 0 0 23px 0;
        color: #02224f;
        font-size: 36px;
        line-height: 1.17;
        letter-spacing: 3.6px;
        text-align: left;
      }
    
      .main-section--description {
        font-size: 24px;
        font-weight: 300;
        line-height: 1.33;
        letter-spacing: 1.2px;
        text-align: left;
        color: #5e779c;
        margin: 0 0 114px 0;
        width: 25.104vw;
      }
    }
  }
}

/*** gsap ***/
.expertise-wrapper{
  padding-top: 280px;
  padding-bottom: 300px; 
}

.main-section--row {
  .info-section {
    .info-wrapper {
      position: sticky;
      top: 14vw;
    }
  }
}

.main-section--row {
  margin-bottom: 100px;
}


/*** responsiveness ***/
@media (max-width: 1680px) {
  .expertise-wrapper{
    .expertise-item{
      .expertise-info{
        .expertise-description{
          max-width: 28.844vw;
        }
      }
    }
  }
}


/*  iPad - Landscape  */
@media (max-width: 1366px) {
  .expertise-wrapper{
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    .expertise-item{
      &:last-of-type{
        margin-bottom: 0;
      }
      .expertise-info{
        .expertise-description{
          max-width: unset;
        }
      }
    }
  }

  .main-section--row {
    flex-direction: column;
    margin-bottom: 0;
    .info-section {  
      .info-wrapper {
        margin-left: 0;
        display: flex;
        align-items: center;
        .main-section--heading, .main-section--description {
          text-align: center;
          width: 100%;
        }
        .main-section--heading {
          margin-bottom: 6.267vw;
          padding-top: 12vw;
        }
        .main-section--description {
          margin-bottom: 80px;
          width:  667px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .expertise-wrapper{
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    .expertise-item{
      &:last-of-type{
        margin-bottom: 0;
      }
      .expertise-image {
        margin-right: 5.467vw;
      }
      .expertise-info{
        .expertise-title{
          font-size: 2.5vw;
        }
        .expertise-description{
          font-size: 2.400vw;
          max-width: unset;
        }
      }
    }
  }

  .main-section--row {
    flex-direction: column;
    .info-section {  
      .info-wrapper {
        margin-left: 0;
        .main-section--heading, .main-section--description {
          text-align: center;
          width: 100%;
        }
        .main-section--heading {
          font-size:  4.800vw;
          margin-bottom: 6.267vw;
          padding-top: 20vw;
        }
        .main-section--description {
          font-size: 3.200vw;
          margin-bottom: 80px;
        }
      }
    }
  }

}

@media (max-width: 450px) {
  .expertise-wrapper{
    .expertise-item{
      .expertise-image{
        width: 15vw;
        height: 15vw;
      }
      .expertise-info {
      
        .expertise-title {
          font-size: $phone-expertise-item-heading;
          letter-spacing: $phone-expertise-item--letter-spacing;
        }

        .expertise-description{
          font-size: $phone-expertise-item-description;
        }
      }
    }
  }

  .main-section--row {
    .info-section {  
      .info-wrapper {
        .main-section--heading {
          font-size: $phone-heading;
        }
        .main-section--description {
          font-size: $phone-description;
        }
      }
    }
  }
}
