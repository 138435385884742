#footer {
  will-change: transform;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 87px 127px 40px;
  background-color: white;
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;

  .footer-information{
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    margin-bottom: 40px;
    border-bottom: 1px solid #5e779c;

    .footer-information-item{
      display: flex;

      img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
  
      .text-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-width: 174px;
        .text {
          font-size: 16px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: 0.8px;
          text-align: left;
          color: #fff;

          &.text--clickable{
            &:hover{
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .footer-details{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: 0.7px;
    text-align: left; 
    color: #02224f;
    width: 100%;
    align-items: center;

    .social-links{
      display: flex;
      .social-links--icon-wrap{
        width: 30px;
        height: 30px;
        margin-right: 15px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover{
          background-color: #fff;
        }

        &:last-child {
          margin-right: 0;
        }
        .social-link--icon{
          color: #02224f;
          width: 16px;
        }
        .social-link--icon--fb{
          width: 10px;
        }
      }
    }
  }
}

/*** responsiveness ***/
@media (max-width: 1366px) {
  .footer-wrapper {
    margin-top: 60px;
    padding:  80px 40px 40px;
  }
}

@media (max-width: 768px) {
  .footer-wrapper {
    height: auto;
    padding: 3.067vw 6.667vw 4vw;
    width: 112%;
    .footer-information{
      flex-wrap: wrap;
      padding: 5.333vw 0px;
      margin-bottom: 4vw;
      .footer-information-item {
        margin-bottom: 4vw;

        img {
          width: 6.667vw;
          height: 6.667vw;
          margin-right: 2.667vw;
        }

        .text-wrap{
          .text {
            font-size: 2.133vw;
          }
        }
      }
    } 
    .footer-details {
      flex-direction: column-reverse;
      font-size: 2.400vw;

      .social-links {
        justify-content: center;
        border-bottom: 1px solid;
        padding-bottom: 4vw;
        margin-bottom: 4vw;
        width: 100%;
        .social-links--icon-wrap{
          width: 30px;
          height: 30px;
        }
      }

      span {
        text-align: center;
      }
    }
  }
}

@media (max-width: 450px) {
  .footer-wrapper {
    .footer-information{
      padding-top: 8vw;

      .footer-information-item {
        .text-wrap {
          .text {
            font-size: $phone-footer-text;
            letter-spacing: 0px;
          }
        }
      } 
    } 

    .footer-details {
      span {
        font-size: $phone-footer-text;
      }
    }
  }
}