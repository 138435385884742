.header-2021-wrap {
  --burger-wrap-width: 40px;
  --burger-wrap-height: 40px;
  --burger-width: 24px;
  --burger-height: 3px;
  --burger-center-transform--translateX: -25px;
  --burger-breads-transform--translateY-negative: -8px;
  --burger-breads-transform--translateY: 8px;
  --burger-breads-translateXY: 17.5px;
  --burger-breads-translateXY-negative: -17.5px;

  .menu-btn {
    position: relative;
    width: var(--burger-width);
    height: var(--burger-width);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .5s ease-in-out;
    z-index: 10;
  }
  .menu-btn__burger {
    width: var(--burger-width);
    height: var(--burger-height);
    background: #6881a5;
    border-radius: 5px;
    transition: all .5s ease-in-out;
  }
  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: '';
    position: absolute;
    width: var(--burger-width);
    height: var(--burger-height);
    background: #6881a5;
    border-radius: 5px;
    transition: all .5s ease-in-out;
  }
  .menu-btn__burger::before {
    transform: translateY(var(--burger-breads-transform--translateY-negative));
  }
  .menu-btn__burger::after {
    transform: translateY(var(--burger-breads-transform--translateY));
  }
  /* ANIMATION */
  .menu-btn.open .menu-btn__burger {
    transform: translateX(var(--burger-center-transform--translateX));
    background: transparent;
    box-shadow: none;
  }
  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(var(--burger-breads-translateXY), var(--burger-breads-translateXY-negative));
    background: #e1e6ed;
  }
  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(var(--burger-breads-translateXY), var(--burger-breads-translateXY));
    background: #e1e6ed;
  }
}


.header-2021-wrap {
  width: 100%;
  position: sticky; 
  top: 0;
  z-index: 16;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  .header-2021{
    width: 100%;
    height: 100px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10.938vw;

    .header-2021-logo{
      img{
        width: 210px;
        height: auto;
      }
    }

    .navbar-toggle {
      display: none;
    }

    .header-2021-navigation{
      list-style-type: none;
      display: flex;
      margin: 0;

      &.mobile-nav{
        display: none;
      }

      .mobile-nav-content-wrap{
        display: none;
      }
      
      li {
        margin-right: 3.125vw;    
        letter-spacing: 3.6px;
        text-transform: uppercase;
        font-family: 'Qanelas Bold';
        &:last-of-type{
          margin-right: 0;
        }

        a {
          text-decoration: none;
          color: #6881a5;
          &:hover{
            color: #516683;
          }
        }
      }
    }

    .social-link--icon{
      width: 4vw;
    }
    .social-link--icon-fb{
      width: 3vw;
    }
  }
}

/*** responsiveness ***/
@media (max-width: 1680px) {
  .header-2021-wrap .header-2021 .header-2021-navigation li {
    margin-right:  3.571vw;
  }
}

@media (max-width: 1029px) {
  .header-2021-wrap {
    position: fixed;
    .header-2021{
      padding: 0 8vw !important;
      .header-2021-navigation{
        flex-direction: column;
        display: none;

        &.highlights {
          display: flex;
        }

        &.mobile-nav{
          display: flex;
          flex-direction: column;
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          max-height: 100vh;
          padding: 0;
          margin: 0;
          background-color: #001C4F;
          li {
            display: none;
          }
          .mobile-nav-content-wrap{
            display: flex;
            flex-direction: column;
            color: #e1e6ed;
            overflow-y: hidden;
    
            .mobile-nav-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 100px;
              padding: 0 8vw;
              margin-bottom: 50px;
              min-height: 100px;

              .navbar-toggle span{
                color: #e1e6ed;
                width: 110px;
                margin-right: 40px;
                text-align: left;
              }

              .logo img{
                max-width: 278px;
                width: 37.3vw;
              }
            }

            ul {
              padding: 0 10.5vw;
                li {
                display: block;
                font-family: "Qanelas Bold";
                font-size: 4.4vw;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: 3.6px;
                text-align: left;
                margin-bottom: 5.400vw;
                text-transform: uppercase;
                &:last-of-type{
                  padding-bottom: 100px;
                  border-bottom: 1px solid #6982A7;
                  margin-bottom: 60px;
                }
                a{
                  color: #e1e6ed;
                }
              }

              .social-links {
                display: flex;
                justify-content: center;
                .social-links--icon-wrap {
                  width: 6vw;
                  height: 6vw;
                  margin-right: 6vw;
                  background-color: #6982A7;
                  color: #001C4F;
                  border-radius: 1.667vw;
                  justify-content: center;
                  align-items: center;
                  display: flex;
                  font-size: 5vw;

                  &:last-of-type{
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }

      .header-2021-logo{
        img{
          max-width: 278px;
          width: 37.3vw;
        }
      }

      .navbar-toggle {
        display: flex;
        align-items: center;

        &:hover{
          cursor: pointer;
        }
        span {
          font-family: "Qanelas Bold";
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 4.8px;
          text-align: right;
          color: #6881a5;
          margin-right: 20px;
          width: 110px;
          margin-right: 0;
          text-align: left;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header-2021-wrap {
    .header-2021{
      .header-2021-navigation{
        &.mobile-nav{
          .mobile-nav-content-wrap{
            .mobile-nav-header {
              padding: 0 8vw;
              margin-bottom: 50px;
              .navbar-toggle span{
                font-size: 3.2vw;
                width: 13.8vw;
              }
              .logo img{
                width: 37.3vw;
              }
            }

            ul {
              padding: 0 13.5vw;
                li {
                font-size: 6.400vw;
                margin-bottom: 8.400vw;
                &:last-of-type{
                  padding-bottom: 100px;
                  margin-bottom: 60px;
                }
              }

              .social-links {
                .social-links--icon-wrap {
                  width: 8vw;
                  height: 8vw;
                  margin-right: 8vw;
                  border-radius: 2.667vw;
                  font-size: 5vw;

                  &:last-of-type{
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }

      .header-2021-logo{
        img{
          width: 37.3vw;
        }
      }

      .navbar-toggle {
        span {
          font-size: 3.2vw;
          margin-right: 20px;
          width: 11vw;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .header-2021-wrap {
    --burger-wrap-width: 20px;
    --burger-wrap-height: 20px;
    --burger-width: 12px;
    --burger-height: 2px;
    --burger-center-transform--translateX: -12.5px;
    --burger-breads-transform--translateY-negative: -5px;
    --burger-breads-transform--translateY: 5px;
    --burger-breads-translateXY: 8.75px;
    --burger-breads-translateXY-negative: -8.75px;
    .header-2021 {
      .navbar-toggle span {
        font-size: 3.2vw;
      }

      .header-2021-navigation.mobile-nav .mobile-nav-content-wrap .mobile-nav-header .navbar-toggle span {
        width: auto;
        margin-right: 25px;
      }
    }
  }
}
