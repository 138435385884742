
.main-section.success-stories{
    .main-section--description{
        width: 37.979vw
    }
}
  

.main-wrap.success-stories{
    background-color: #e1e6ed;
}

.page-navigation {
    font-size: 14px;
    display: block;
    width: 100%;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 200px;
    display: flex;
    justify-content: space-between;
    padding: 0 17.188vw;

    a {
        font-family: "Qanelas Bold";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 3.2px;
        text-align: left;
        color: #0559d1;
        text-transform: uppercase;
        &:hover{
            text-decoration: none;
        }

        .caret {
            width: 10px;
            fill: #0559d1;
            margin-right: 0px;
            margin-left: 20px
        }

        .caret-previous{
            transform: rotate(180deg);
            margin-right: 20px;
        }
    }

    .next {
        text-align: right;
    }

    &.back-to-giving {
        margin: 60px 0;
    }
  }

.post-content {
    margin-right: auto;
    margin-left: auto;
    margin-top: 80px;
    line-height: 24px;
    font-style: normal;
    font-size: 16px;
    color: rgb(100, 100, 100);
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    width: 51.667vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        font-family: "Qanelas Bold";
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: 3.6px;
        text-align: left;
        color: #02224f;
        text-transform: uppercase;
        margin-bottom: 56px;
    }
    .highlight {
        margin-right: 20px;
    }
    .success-wrapper {
        width: 100%;
    }
    .main-image {
        max-width: 100%;
        height: 542px;
        width: 100%;
        object-fit: cover;
        border-radius: 30px;
        margin-bottom: 60px;
    }
    table {
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px dotted #808080;
        width: 100%;
        display: flex;
        color: rgb(100, 100, 100);
        font-family: Raleway;
        font-size: 14px;
        td {
            padding-right: 20px;
        }
        display: none;
    }
    p {
        font-family: "Qanelas Regular";
        font-size: 24px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 1.2px;
        text-align: left;
        color: #5e779c;
        margin-bottom: 55px;
    }
    strong {
        font-weight: bold;
    }
    figure {
        margin: 0;
        iframe {
            width: 100%;
            height: 700px;
        }
    }
    .images-container {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;

        img {
            border-radius: 30px;
            height: 542px;
            width: 100%;
            object-fit: cover;
            margin-bottom: 60px;

            &:last-of-type{
                margin-bottom: 100px;
            }
        }
    }
}

/*** responsiveness ***/
@media (max-width: 1366px) {
    .post-content {
        width: 75vw;
    }

    .page-navigation {
        padding: 0px 11.188vw;
    }
}

@media (max-width: 768px) { 
    .post-content {
        .main-image {
            height: 50vw;
        }

        .images-container img{
            height: 50vw;
            margin-bottom: 8vw;

            &:last-of-type{
                margin-bottom: 8vw;
            }
        }

        p {
            font-size: 3.200vw
        }
    }
    .page-navigation {
        width: 100%;
        padding: 0 30px;
        a {
            font-size: 3.200vw
        }
    }
}

@media (max-width: 768px) { 
    .post-content {
        p{ 
            font-size: $phone-success-story-post-content;
            margin-bottom: 30px;
        }
    }
}
