body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Qanelas Regular';
  background-color: #e1e6ed;
}

* {
  box-sizing: border-box;
}
 
.main-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 8.333vw;
  background-color: #fff;

  &.page-bottom-wrap {
    background-color: #e1e6ed;
  }
}

.rounded-section {
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
}

.main-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-section--heading {
    font-family: 'Qanelas Bold';
    margin: 0 0 56px 0;
    color: #02224f;
    font-size: 36px;
    line-height: 1.17;
    letter-spacing: 3.6px;
    text-align: center;
  }

  .main-section--description {
    color: #5e779c;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: 1.2px;
    text-align: center;
    color: #5e779c;
    margin: 0 0 114px 0;
    width: 677px;
  }
}

.grecaptcha-badge { visibility: hidden; }

img.contain-image{
  object-fit: contain !important;
}


/*** responsiveness ***/
@media (max-width: 1366px) {
  .main-wrap {
    padding-bottom: 20px;
  }
  .main-wrap.page-bottom-wrap {
    padding-bottom: 0px;
  }
}

@media (max-width: 768px) {
  html {
    overflow-x: hidden;
  }
  
  .main-section {
    .main-section--heading {
      margin: 0 0 7.467vw 0;
      font-size: 4.8vw;
    }
    .main-section--description {
      font-size: 3.2vw;
      width: 100%;
      margin-bottom: 10vw;
    }
  }
}

@media (max-width: 450px) {
  .main-section {
    .main-section--heading {
      font-size: $phone-heading;
    }
    .main-section--description {
      font-size: $phone-description;
    }
  }
}